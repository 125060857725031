<div class="row mt-4 mb-2">
    <div class="col-12">
        <h3>
            Amateur Voice Over & Character Impersonations
        </h3>
    </div>
</div>

<div class="row mb-2">
    <div class="col-12">
        <app-vo-header></app-vo-header>
    </div>
</div>

<div class="row mb-2">
    <div class="col-12 d-flex flex-wrap">
        <span class="m-2" *ngFor="let model of data">
            <app-vo-card [model]="model"></app-vo-card>
        </span>
    </div>
</div>

<div class="row mb-4">
    <div class="col-12">
        <app-vo-footer></app-vo-footer>
    </div>
</div>
<h3 class="mt-2">Game 3</h3>

<div class="card mb-2">
    <h5 class="card-header">Information</h5>
    <div class="card-body">
        <img src="/assets/game-3/game3_icon_rounded.png" alt="Game 3 icon." class="pull-left px-2" />

        <p>
            A third game that is currently in development...
        </p>

    </div>
</div>
<div class="card">
    <div class="card-header h5">
        The End
    </div>
    <div class="card-body">
        <p>
            This should give you an idea of the voice range available.
        </p>
        <p>
            Send your lines to <a href="mailto:pat@goatleap.com?Subject=VO%20Work%20Inquiry" target="_top">
                pat(at))goatleap.com</a> with the details of your project and I'll get back to you.
        </p>
        <p>
            <a routerLink="/">Return to goatleap.com</a>.
        </p>
    </div>
</div>
import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-post-002',
    templateUrl: 'post-002.component.html',
    standalone: false
})
export class Post002Component {
    heading = 'Flip Stick Is Out!';
    postDate = new Date('2012-11-13');

    constructor(public linkService: LinkService) { }
}

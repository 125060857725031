import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LinkService {

    constructor() { }

    flipStickDownloadLink(): string {
        return 'https://itunes.apple.com/us/app/flip-stick/id561661389?mt=8&uo=4';
    }

    fallBricksDownloadLink(): string {
        return 'https://itunes.apple.com/au/app/fall-bricks/id571209400?mt=8&uo=4';
    }
}

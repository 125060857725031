<app-post [heading]="heading" [postDate]="postDate">

    <div class="text-center mb-2">
        <img src="/assets/fall-bricks/fallbricks_icon_rounded.png" class="img-fluid" alt="Fall Bricks Logo" />
    </div>
    <p>
        A new build of Fall Bricks has been released. This build is version 1.4.1. The game is mostly the same. The code
        has been updated to run on more recent versions of iOS.
    </p>
    <p>
        <a href="{{linkService.fallBricksDownloadLink()}}">Download it here.</a>
    </p>
    <p>
        Enjoy!
    </p>

</app-post>
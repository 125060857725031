import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-post-003',
    templateUrl: 'post-003.component.html',
    standalone: false
})
export class Post003Component {
    heading = 'Flip Stick Update 1.0.1';
    postDate = new Date('2012-11-13');

    constructor(public linkService: LinkService) { }
}

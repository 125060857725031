<h3 class="mt-2">Projects</h3>
<div class="row">
    <div class="col-md-4">
        <app-flip-stick-panel></app-flip-stick-panel>
    </div>
    <div class="col-md-4">
        <app-fall-bricks-panel></app-fall-bricks-panel>
    </div>
    <div class="col-md-4">
        <app-game-3-panel></app-game-3-panel>
    </div>
</div>

<h3 class="mt-2">News Feed</h3>

<div class="row mb-2">
    <div class="col">
        <app-post-020></app-post-020>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-019></app-post-019>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-018></app-post-018>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-017></app-post-017>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-016></app-post-016>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-015></app-post-015>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-014></app-post-014>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-013></app-post-013>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-012></app-post-012>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-011></app-post-011>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-010></app-post-010>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-009></app-post-009>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-008></app-post-008>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-007></app-post-007>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-006></app-post-006>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-005></app-post-005>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-004></app-post-004>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-003></app-post-003>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-002></app-post-002>
    </div>
</div>
<div class="row mb-2">
    <div class="col">
        <app-post-001></app-post-001>
    </div>
</div>

<app-post [heading]="heading" [postDate]="postDate">

    <p>
        Goat Leap Games now has a new logo and website!
    </p>

    <div class="mb-2 text-center">
        <strong>New Website</strong>
    </div>
    <div class="mb-2 text-center">
        <img src="/assets/news-feed/post_011_website.png" class="img-fluid" alt="New website." width="400px" />
    </div>

    <p>
        I've been working on the 3rd version of the website for a while, and I finally had some free time to finish it.
        It has a responsive design to allow for browsing on phones and tablets.
    </p>

    <div class="mb-2 text-center">
        <strong>New Logo</strong>
    </div>
    <div class="mb-2 text-center">
        <img src="/assets/goat_leap_games_logo_x8.png" class="img-fluid" alt="New logo." />
    </div>

    <p>
        Changing a logo is a big deal, but I think that Goat Leap Games is small enough to be able to make this change.
        I have t-shirts and business cards with the old logo on them, so I'll have to get them replaced sometime soon. I
        really like the new logo, and I think it was the right choice to update it.
    </p>

</app-post>
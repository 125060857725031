import { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { HideService } from '../services/hide.service';
import { ModelData } from './data/model-data';
import { VoCardData } from './models/vo-card-data';

@Component({
    selector: 'app-root',
    templateUrl: './vo.component.html',
    styleUrls: ['./vo.component.css'],
    standalone: false
})
export class VoComponent implements OnDestroy {

    data: VoCardData[];

    constructor(
        private hideService: HideService
    ) {
        this.data = new ModelData().data;
        this.hideService.set(true);
    }

    ngOnDestroy() {
        this.hideService.set(false);
    }
}

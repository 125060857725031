<div class="card mb-2">
    <h5 class="card-header">Flip Stick</h5>
    <div class="card-body">
        <div class="text-center mb-2">
            <img src="/assets/flip-stick/flipstick_icon_rounded.png" alt="Flip Stick Logo" />
        </div>
        <p class="mb-2">
            Our first game: a simple tapping game for iOS. It's small and free!
        </p>
        <div class="mb-2">
            First release:
            <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%">
                    100%
                </div>
            </div>
        </div>
        <div class="mb-2">
            Current version: 1.2
            <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%">
                    100%
                </div>
            </div>
        </div>
        <div class="mb-2 text-center">
            <button class="btn btn-primary me-2" routerLink="games/flip-stick" role="button">More</button>
            <a class="btn btn-primary" href="{{linkService.flipStickDownloadLink()}}" role="button">Download</a>
        </div>
    </div>
</div>
<h3 class="mt-2">About</h3>

<div class="card mb-2">
    <h5 class="card-header">About Goat Leap Games</h5>
    <div class="card-body">
        <p>
            Goat Leap Games is small games development team based in Melbourne, Australia. The team was started in 2011
            as "IronSummers", and changed names to Goat Leap Games in early 2013.
        </p>

        <p>
            Goat Leap Games makes simple, small scale games that cost nothing to make. Currently, we are working on
            small games for mobile platforms. Once we have released some of our games, we might be able to expand to
            other platforms.
        </p>

        <p>
            We have released 2 games, and the third is currently in development.
        </p>
    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">About This Website</h5>
    <div class="card-body">
        <p>
            This website was made using <a href="https://angular.io/">Angular</a> and
            <a href="http://getbootstrap.com/">Bootstrap</a>.
        </p>
    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">App Privacy Policy</h5>
    <div class="card-body">
        <p>
            A privacy policy is required to submit apps to some digital stores. Here's our simple <a
                routerLink="/about/privacy-policy">privacy policy</a>.
        </p>
    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">More Details</h5>
    <div class="card-body">
        <p>
            For more information, please send us a message using the <a routerLink="/contact">contact</a> page.
        </p>
    </div>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HideServiceData } from '../models/hide-service-data';

@Injectable({ providedIn: 'root' })
export class HideService {

    private valueSubjectBehavior: BehaviorSubject<HideServiceData> = new BehaviorSubject(new HideServiceData());

    readonly valueObservable: Observable<HideServiceData> = this.valueSubjectBehavior.asObservable();

    constructor() {

    }

    set(value: boolean) {
        console.log(value);
        const data = new HideServiceData();
        data.hideElements = value;
        this.valueSubjectBehavior.next(data);
    }
}

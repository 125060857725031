import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-post-004',
    templateUrl: 'post-004.component.html',
    standalone: false
})
export class Post004Component {
    heading = 'Finding Flip Stick on the AppStore';
    postDate = new Date('2012-11-19');

    constructor(public linkService: LinkService) { }
}

<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/flip-stick/flipstick_icon_rounded.png" class="img-fluid" alt="Flip Stick icon" />
    </div>

    <p>
        <a href="/games/flip-stick">Flip Stick</a> update 1.0.1 has just been released on the AppStore.
    </p>

    <p>
        This update is just a change to the app's icon bundle text, as I accidentally left it as "flipstick" (the app
        bundle name) when it was should have been "Flip Stick"; my mistake. AppStore teething problems, I guess!
    </p>

    <p>
        Please go
        <a href="{{linkService.flipStickDownloadLink()}}">download or update</a> for free and
        <a href="/contact">let me know what you think.</a>
    </p>

</app-post>
<app-post [heading]="heading" [postDate]="postDate">

    <p>
        Game 3 is still not ready to show, but early development is going well.
    </p>

    <p>
        As I said in the last dev update, game 3 is based on a homebrew Nintendo DS prototype game that I made in my
        first year of university. It was a turn-based strategy game where you could take your turn, then pass the system
        to the next player. I made it so that me and my brother could play something new while we were both passengers
        in the car for the next family holiday.
    </p>

    <p>
        I made a new prototype of the game in C# and MonoGame, but I wanted to move back to C++, so I am porting the
        work that I have done so far from C# to C++, with OpenGL and SDL.
    </p>

    <p>
        I believe that all of the programming hurdles have been addressed, so now the biggest concern with the project
        is the amount of art assets needed. Because if this, I'm aiming to have a small environment tile set to make
        maps with, and 3-to-4 different types of units to move around the map. There are many other art assets and
        design decisions to account for, but I'll post more about that when there is more to say.
    </p>

    So in summary:
    <ul>
        <li>Early development going well, not ready to show yet.</li>
        <li>Turn-based strategy/ tactics game for PC.</li>
        <li>C++, OpenGL, and SDL.</li>
        <li>All programming hurdles have been addressed.</li>
        <li>Small environment tile set for building maps.</li>
        <li>3-to-4 different types of units.</li>
        <li>More design info coming when it is ready.</li>
    </ul>

</app-post>
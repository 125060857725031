import { Component } from '@angular/core';

@Component({
    selector: 'app-post-016',
    templateUrl: 'post-016.component.html',
    standalone: false
})
export class Post016Component {
    heading = 'Going into the tank to work on game 3...';
    postDate = new Date('2016-01-17');
}

<app-post [heading]="heading" [postDate]="postDate">

    <p>
        I haven't done a post since September last year, so it's time for an update.
    </p>

    <b>Flip Stick 1.1</b>

    An update for Flip Stick is available. This update includes:

    <ul>
        <li>Some small bug fixes related to saving data.</li>
        <li>A performance update: we've tried to make the game run better. We've tested it on all the i-devices we have
            access to, so hopefully it runs well on yours! Please tell us if you find any issues.</li>
        <li>A graphics update: all the graphics are now vectors, and should look nice and crisp on devices.</li>
        <li>iOS 7: some of the game's original code used some deprecated functions, so we've re-written the game to only
            use current functions.</li>
    </ul>

    <p>
        It's only 2.5 megabytes, so please go <a
            href="https://itunes.apple.com/us/app/flip-stick/id561661389?mt=8&amp;ign-mpt=uo%3D4">download it from the
            AppStore</a> and give it a try.
    </p>

    <b>What's Next?</b>
    <p>
        Next, we want to update the Goat Leap website. The current format is looking a little dated, and we'd like to
        change it to look nicer on mobile devices. Responsive design!
        After updating the website, we'd like to address some issues/bugs that have been found in Fall Bricks, and give
        it an update like the one we just applied to Flip Stick.
    </p>

    <b>Progress on Game 3</b>
    <p>
        Game 3 is still happening, but it's really on the back-burner until we update the website and update Fall
        Bricks.
        Most of the gameplay systems have been designed on paper, and most of the programming roadblocks have been
        addressed, but it doesn't don't have any graphics yet, and I don't want to show it until it looks nice.
    </p>

    <b>More Dev Updates?</b>
    <p>
        Like I said before, it's been a long time since our last update (almost 9 months). Sometimes finding time for
        game dev can be challenging, and when you go to work and program all day, you don't always want to program all
        night (but sometimes you do, and it's the best). Hopefully we do more game dev, and can write more posts about
        it.
    </p>
</app-post>
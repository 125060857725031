<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/fall-bricks/fallBricks_screenshot_2.png" class="img-fluid" alt="Fall Bricks screenshot." />
    </div>

    <p>
        An update for Fall Bricks has just hit the AppStore!
    </p>
    <p>
        The new update includes a fix for the crash bug that appeared a while ago.
    </p>

    The complete change list is below:
    <ul>
        <li>Score system update: the score system had been updated to track a single score rather than 3 statistics.
        </li>
        <li>Gameplay update: the game will play at the same speed and the difficulty increase will come from the
            randomisation of incoming brick colours.</li>
        <li>Music trigger update: music changes will now trigger as the screen fills up with bricks.</li>
        <li>Removed peaceful mode.</li>
        <li>Updated build for new iPad and iPhone models.</li>
        <li>Miscellaneous bug fixes!</li>
    </ul>

    <a href="{{linkService.fallBricksDownloadLink()}}">Update or download here.</a>

</app-post>
<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/flip-stick/flipstick_icon_rounded.png" class="img-fluid" alt="Flip Stick icon" />
    </div>

    <p>
        <a href="games/flip-stick">Flip Stick</a> has just been released on the AppStore.
    </p>
    <p>
        Please go
        <a href="{{linkService.flipStickDownloadLink()}}">download it</a> for free and
        <a href="/contact">let me know what you think.</a>
    </p>

</app-post>
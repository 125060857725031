import { Component } from '@angular/core';

@Component({
    selector: 'app-post-001',
    templateUrl: 'post-001.component.html',
    standalone: false
})
export class Post001Component {
    heading = 'An update on Flip Stick development';
    postDate = new Date('2012-09-26');
}

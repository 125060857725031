<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/flip-stick/flipstick_icon_rounded.png" class="img-fluid" alt="Flip Stick icon" />
    </div>

    <p>
        <a href="games/flip-stick">Flip Stick</a> update 1.0.2 is up on on the AppStore. This update fixes a
        bug that can sometimes make the game crash the first time the it runs.
    </p>

    <p>
        Please go <a href="{{linkService.flipStickDownloadLink()}}">download or update Flip
            Stick</a> for <b><i>free</i></b> and <a href="/contact">let me know what you think.</a>
    </p>

</app-post>
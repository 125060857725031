<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/fall-bricks/fallBricks_logo_x2.png" class="img-fluid" alt="Fall Bricks logo." />
    </div>

    <p>
        Our new game is finally out! It's called <b>Fall Bricks</b>, a one-button puzzle game for iOS.
    </p>

    <a href="{{linkService.fallBricksDownloadLink()}}">You can download it here.</a>

    <p>
        For more details about it, check out the <a href="/games/fall-bricks/">webpage for Fall Bricks here</a>
        including samples of Fall Bricks music by artist <b>Tom Fitzsimmons</b>.
    </p>

</app-post>
<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/news-feed/post_009_launcher.png" class="img-fluid" alt="Game 3 launcher." />
    </div>

    <p>
        Work has started on our third game. It will be for PC, and we have no-idea when it will be finished. We've
        decided on the type of game we'd like to make, and now we are testing different mechanics, refining the design,
        deciding on scope, and seeing if it's possible.
    </p>

    <p>
        The image above is of the game's current launcher. It lets you choose if you want to run the editor or the game.
        Most of the work is going into the editor at the moment.
    </p>

</app-post>
<div class="my-2 text-center">
    <a routerLink="/">
        <img src="/assets/goat_leap_games_logo_x8.png" alt="Goat Leap Games logo." class="img-fluid" />
    </a>
</div>

<div class="text-center mb-2">
    A small indie games team in Melbourne, Australia.
</div>

<nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-light border rounded-sm">
    <button class="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row mx-2" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
            <li class="nav-item">
                <a class="nav-link" routerLink="/" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>
            <li class="nav-item dropdown" appDropDown>
                <a class="nav-link dropdown-toggle clickable">Games</a>
                <div class="dropdown-menu">
                    <div class="dropdown-header">Released Games</div>
                    <a class="dropdown-item" routerLink="/games/flip-stick" routerLinkActive="active">Flip Stick</a>
                    <a class="dropdown-item" routerLink="/games/fall-bricks" routerLinkActive="active">Fall Bricks</a>
                    <div class="dropdown-header">Games In Development</div>
                    <a class="dropdown-item" routerLink="/games/game-3" routerLinkActive="active">Game 3</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/about" routerLinkActive="active">About</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/contact" routerLinkActive="active">Contact</a>
            </li>
        </ul>
    </div>
    <div class="d-sm-inline-flex flex-sm-row mx-2">
        <div>
            <ul class="navbar-nav flex-grow d-flex align-items-end">
                <li class="nav-item me-2">
                    <a class="nav-link fa fa-facebook" href="https://www.facebook.com/GoatLeap"></a>
                </li>
                <li class="nav-item">
                    <app-ko-fi-button></app-ko-fi-button>
                </li>
            </ul>
        </div>
    </div>
</nav>
<app-post [heading]="heading" [postDate]="postDate">
    <p>
        Work on game 3 is progressing sporadically. Between family and work commitments, there is minimal time to commit
        to the project.
    </p>
    <p>
        With that said, a lot of good work has been done on the game, and progress is being made towards version 1.0,
        which will be on iOS.
    </p>
    <p>
        Some positive notes:
    </p>
    <ul>
        <li>
            The game engine is mostly done and functional.
        </li>
        <li>
            I've developed a robust level editor tool that can easily drop levels into the game.
        </li>
        <li>
            All graphics are original, with plenty already in the game.
        </li>
    </ul>

    <p>
        Some challenges ahead:
    </p>
    <ul>
        <li>
            Menus are functional, but they need to be updated graphically.
        </li>
        <li>
            Many sprites and background still need to be created.
        </li>
        <li>
            There is audio direction, but no audio assets yet.
        </li>
        <li>
            There's a blocker issue with shaders: I'm seeing different results with shaders on hardware vs simulation,
            so a fix is needed.
        </li>
        <li>
            There's plenty of level design work to do. The goal is to have about 60 levels: 6 worlds
            of 10 levels each.
        </li>
    </ul>

    <p>
        Here's a small visual preview:
    </p>

    <div class="text-center mb-2">
        <img src="/assets/news-feed/post_019_game3.png" class="img-fluid" alt="Game 3 preview" />
    </div>

</app-post>
import { Component } from '@angular/core';

@Component({
    selector: 'app-post-014',
    templateUrl: 'post-014.component.html',
    standalone: false
})
export class Post014Component {
    heading = 'Game 3 Dev Update 07/Feb/2015';
    postDate = new Date('2015-02-07');
}

<app-post [heading]="heading" [postDate]="postDate">

    <p>
        Development on Game 3 is going well, but there is still a lot of work to do.
    </p>
    <p>
        It's time for some "radio silence" until Game 3 is ready to show, so there probably won't be many posts here for
        some time.
    </p>

</app-post>
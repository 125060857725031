<h3 class="mt-2">Flip Stick</h3>

<div class="card mb-2">
    <h5 class="card-header">Information</h5>
    <div class="card-body">
        <img src="/assets/flip-stick/flipstick_icon_rounded.png" alt="Flip Stick app icon." class="pull-left px-2" />

        <p>
            Flip Stick is a simple tapping game for iOS. It's our first game.
        </p>
        <p>
            In Flip Stick, you are a high-board diver who wants to
            do as many flips in a single dive. Tap the screen to
            do as many flips as you can to get a high score, and
            impress the random seagull. Challenge your
            friends to beat your high score, and try to get the high
            score on their device!
        </p>
        <p>
            <b>Platform :</b> iOS (want it on another platform? <a routerLink="/contact">Let me know</a>)
        </p>
        <p>
            <b>Download Size :</b> About 6.8 MB
        </p>
        <p>
            <b>Price :</b> Free
        </p>

        <a href="{{linkService.flipStickDownloadLink()}}">Download it here.</a>
    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">Screenshots</h5>
    <div class="card-body">
        <div class="row">
            <div class="col-md-4 text-center mb-2">
                <img class="rounded screenshot-contain" src="/assets/flip-stick/flipStick_screenshot_1.png"
                    alt="Flip Stick screen shot 1." />
            </div>
            <div class="col-md-4 text-center mb-2">
                <img class="rounded screenshot-contain" src="/assets/flip-stick/flipStick_screenshot_2.png"
                    alt="Flip Stick screen shot 2." />
            </div>
            <div class="col-md-4 text-center mb-2">
                <img class="rounded screenshot-contain" src="/assets/flip-stick/flipStick_screenshot_3.png"
                    alt="Flip Stick screen shot 3." />
            </div>
        </div>
    </div>
</div>
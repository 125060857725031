import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-post',
    templateUrl: 'post.component.html',
    standalone: false
})
export class PostComponent {
    @Input() heading: string;
    @Input() postDate: Date;
}

import { Component, OnInit } from '@angular/core';
import { HideServiceData } from './models/hide-service-data';
import { HideService } from './services/hide.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {

    hideElements: boolean;

    constructor(
        private hideService: HideService
    ) {
    }

    ngOnInit() {
        this.hideService.valueObservable.subscribe((data: HideServiceData) => {
            this.hideElements = data.hideElements;
        });
    }
}

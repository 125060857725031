<div class="card" style="width: 19rem;">
    <img [src]="model.graphicPath" class="card-img-top" alt="{{model.name}}">
    <div class="card-body">
        <h5 class="card-title text-center">
            {{model.name}}
        </h5>
        <h6 class="card-title text-center">
            {{model.origin}}
        </h6>
        <p class="card-text">
            {{model.description}}
        </p>
        <div class="text-center">
            <app-vo-stars [count]="model.stars"></app-vo-stars>
        </div>
    </div>
    <div *ngIf="model.showDisclaimer" class="card-footer text-danger text-center">
        <app-vo-disclaimer></app-vo-disclaimer>
    </div>
</div>
<h3 class="mt-2">Fall Bricks</h3>

<div class="card mb-2">
    <h5 class="card-header">Information</h5>
    <div class="card-body">
        <img src="/assets/fall-bricks/fallbricks_icon_rounded.png" alt="Fall Bricks app icon." class="pull-left px-2" />

        <p>
            Fall Bricks is a simple one-button puzzle game for iOS.
        </p>
        <p>
            It's our second game, and creating it gave us a chance to
            expand on the lessons learnt while making Flip Stick.
        </p>
        <p>
            In Fall Bricks, you tap the screen to make bricks fall onto
            the stage. You have to try to make groups of 10 or more of
            the same colour to get points, and those bricks will be
            cleared from the screen. As you get points, the new bricks
            are randomised, making the game harder. If the bricks reach the
            top of the stage, it's game over!
        </p>
        <p>
            We also outsourced the audio for this game. See Music and
            Sound Design below.
        </p>
        <p>
            <b>Platform :</b> iOS (want it on another platform? <a routerLink="/contact">Let me know</a>)
        </p>
        <p>
            <b>Download Size :</b> 8.38 MB
        </p>
        <p>
            <b>Price :</b> Free
        </p>

    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">Music and Sound Design</h5>
    <div class="card-body">
        <p>
            The music and sound design for Fall Bricks was written and created by Tom Fitzsimmons.
        </p>
        <p>
            If you are interested in Tom's work, you can contact him via email at
            <a href="mailto:thomasjfitzsimmons134@gmail.com?Subject=Music%20and%20Sound%20Design"
                target="_top">thomasjfitzsimmons134(at)gmail.com</a>
        </p>
        <p>
            His website is coming soon.
        </p>
        <p>
            You can listen to some samples of the game's music below.
            <br />
            <i>(Your browser must support the audio element.)</i>
        </p>
        <p>
            <b>Fall Bricks Main Theme</b>
        </p>
        <p>
            <audio controls src="/assets/fall-bricks/fallBricks_main_theme.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
        </p>
        <p>
            <b>Fall Bricks Menu Music</b>
        </p>
        <p>
            <audio controls src="/assets/fall-bricks/fallBricks_menu.mp3" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
        </p>
    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">Screenshots</h5>
    <div class="card-body">
        <div class="row">
            <div class="col-md-6 text-center mb-2">
                <img class="rounded" src="/assets/fall-bricks/fallBricks_screenshot_1.png"
                    alt="Fall Bricks screenshot 1." />
            </div>
            <div class="col-md-6 text-center mb-2">
                <img class="rounded" src="/assets/fall-bricks/fallBricks_screenshot_2.png"
                    alt="Fall Bricks screenshot 2." />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 text-center mb-2">
                <img class="rounded" src="/assets/fall-bricks/fallBricks_screenshot_3.png"
                    alt="Fall Bricks screenshot 3." />
            </div>
            <div class="col-md-6 text-center">
                <img class="rounded" src="/assets/fall-bricks/fallBricks_screenshot_4.png"
                    alt="Fall Bricks screenshot 4." />
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header d-flex justify-content-between">
        <span>
            <strong>{{heading}}</strong>
        </span>
        <span>
            <strong>{{postDate | date:'longDate'}}</strong>
        </span>
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>
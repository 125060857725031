<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/flip-stick/flipstick_icon_rounded.png" alt="Flip Stick icon" />
    </div>

    <p>
        Development on Flip Stick is going well. The game is in its final stages, with all the main functionality done.
        Most of the work is now going into making and improving assets like the graphics and music. These assets will be
        replacing placeholders and shouldn't cause any serious problems.
    </p>
    <p>
        Occasionally, I get a friend to play the game to provide some informal feedback. Sometimes I make changes to the
        game based on this feedback; for example, one friend suggested that the current user name in the name field be
        erased automatically when the user attempts to enter a new one (when the text field is put into focus). As the
        user probably wants to enter an entirely new name, as opposed to modifying the current name, I decided to make
        this simple change, as it made sense. I think small suggestions like this are helping to improve the flow of the
        game.
    </p>
    <p>
        I might be thinking too far forward, but I'm thinking of comments to add to a post-mortem about Flip Stick, but
        I don't want to say too much before it's done. Hopefully the next update will be to announce that the game is
        done and you can go to the Flip Stick page and get it.
    </p>

</app-post>
<app-post [heading]="heading" [postDate]="postDate">

    <p>
        A new update for both Flip Stick and Fall Bricks are in development to update the Goat Leap Games logo.
    </p>

    <p>
        If you would like to recommend any other changes, please use the <a routerLink="/contact">contact page</a> to
        let me know.
    </p>

</app-post>
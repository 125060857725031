<h3 class="mt-2">Contact</h3>

<div class="card mb-2">
    <h5 class="card-header">Email</h5>
    <div class="card-body">
        <p>
            If you have any questions about our games, this website, or would just like to say hi, send an email to
        </p>
        <p class="pl-4">
            <a href="mailto:pat@goatleap.com?Subject=Goat%20Leap%20Games" target="_top">pat(at)goatleap.com</a>
        </p>
    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">Facebook</h5>
    <div class="card-body">
        <a href="https://www.facebook.com/GoatLeap">Goat Leap Games on Facebook</a>
    </div>
</div>
<div class="card mb-2">
    <h5 class="card-header">Game 3</h5>
    <div class="card-body">
        <div class="text-center mb-2">
            <img src="/assets/game-3/game3_icon_rounded.png" alt="Game 3 Logo" />
        </div>
        <p class="mb-2">
            A new game currently in development. More information coming soon.
        </p>
        <div class="mb-2">
            First release:
            <div class="progress">
                <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar"
                    style="width: 50%">
                    50%
                </div>
            </div>
        </div>
        <div class="mb-2">
            No updates planned.
            <div class="progress">
                <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 2%">

                </div>
            </div>
        </div>
        <div class="mb-2 text-center">
            <button class="btn btn-primary" routerLink="games/game-3" role="button">More</button>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-vo-stars',
    templateUrl: 'vo-stars.component.html',
    standalone: false
})
export class VoStarsComponent implements OnInit {

    @Input() count: number;

    filledCount: number;
    unfilledCount: number;

    constructor() {
        this.count = 0;
        this.filledCount = 0;
        this.unfilledCount = 0;
    }

    ngOnInit() {
        this.calculateStars();
    }

    calculateStars() {
        this.filledCount = this.count;
        this.unfilledCount = 5 - this.count;
    }

    counter(count: number) {
        return new Array(count);
    }
}

<app-post [heading]="heading" [postDate]="postDate">

    <p>
        Game 3 is progressing well.
    </p>

    <p>
        The game is based on a Nintendo DS game prototype that I made in 2008, but there isn't much to tell past that.
    </p>

    <p>
        I'll post a screenshot when there is more to show.
    </p>

</app-post>
import { Component } from '@angular/core';

@Component({
    selector: 'app-post-006',
    templateUrl: 'post-006.component.html',
    standalone: false
})
export class Post006Component {
    heading = 'Welcome to Goat Leap';
    postDate = new Date('2013-03-19');
}

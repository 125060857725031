import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: 'privacy-policy.component.html',
    standalone: false
})
export class PrivacyPolicyComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
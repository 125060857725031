import { Component, Input, OnInit } from '@angular/core';
import { VoCardData } from '../models/vo-card-data';

@Component({
    selector: 'app-vo-card',
    templateUrl: 'vo-card.component.html',
    standalone: false
})
export class VoCardComponent implements OnInit {

    @Input() model: VoCardData;

    constructor() {
        this.model = {
            name: 'Name',
            origin: 'origin',
            description: 'description',
            stars: 0,
            graphicPath: 'graphicPath',
            showDisclaimer: false
        };
    }

    ngOnInit() { }

}

<app-post [heading]="heading" [postDate]="postDate">

    <div class="text-center mb-2">
        <img src="/assets/flip-stick/flipstick_icon_rounded.png" class="img-fluid" alt="Flip Stick Logo" />
    </div>
    <p>
        A new build of Flip Stick has been released. This build is version 1.2. The game is mostly the same. The code
        has been updated to run on more recent versions of iOS, and the game now runs in portrait orientation.
    </p>
    <p>
        <a href="{{linkService.flipStickDownloadLink()}}">Download it here.</a>
    </p>
    <p>
        Enjoy!
    </p>

</app-post>
import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-fall-bricks-panel',
    templateUrl: 'fall-bricks-panel.component.html',
    standalone: false
})
export class FallBricksPanelComponent {

    constructor(public linkService: LinkService) { }

}

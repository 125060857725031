import { Component } from '@angular/core';

@Component({
    selector: 'app-post-010',
    templateUrl: 'post-010.component.html',
    standalone: false
})
export class Post010Component {
    heading = 'Development Update - June 11th 2014';
    postDate = new Date('2014-06-11');
}

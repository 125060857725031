<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/flip-stick/flipstick_icon_rounded.png" class="img-fluid" alt="Flip Stick icon" />
    </div>

    <p>
        Some of my friends have had some trouble finding <a href="/games/flip-stick">Flip Stick</a> on the
        AppStore so here are some tips for finding it.
    </p>

    <p>
        For iPhone and iPod Touch users : In the AppStore, use the search field to search for "flipstick" or "Flip
        Stick" and Flip Stick should be the first app returned. It should be recognizable by the logo.
    </p>

    <p>
        For iPad users : This one is little trickier, and seems to be the problem people are having: As Flip Stick is an
        iPhone app, it was not natively made for the iPad, but you can still download it and play it on an iPad. When
        using search on the iPad AppStore, you can swap between iPhone and iPad apps. When you do a search, the results
        default to iPad apps, and then you can swap to see the results for iPhone apps if you need to. The problem is if
        you search for a term that does not return any iPad apps, and then swap to iPhone apps, the results will still
        say that there are no results even if there are some results. So if you search "Flip Stick", no results are
        returned on iPad, and when you swap top iPhone/iPod apps, no results are shown, but we know that there are
        actually results for "Flip Stick" on iPhone/iPod. I'm unsure if this is intentional or a bug. So to find Flip
        Stick on iPad, you have to use some other keywords. If you use the search term "seagull", iPad apps will be
        returned. You won't find Flip Stick in the iPad results, so swap to iPhone results, you should find Flip Stick
        with some other apps. You may have to scroll around to find it.
    </p>

    That was longer than expected, so here's a summary:
    <ol>
        <li>In the iPad AppStore, search for "seagull".</li>
        <li>Swap to iPhone results.</li>
        <li>Find Flip Stick in the other results.</li>
        <li>Download it.</li>
    </ol>

    <p>
        Or <a href="{{linkService.flipStickDownloadLink()}}">use this link</a> while on your device.
    </p>

    <p>
        If anyone has any other troubles finding Flip Stick on the AppStore, Please <a href="/contact">let me know
            here.</a>
    </p>

</app-post>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    HomeComponent,
    FlipStickPanelComponent,
    FallBricksPanelComponent,
    Game3PanelComponent,
} from './home';
import {
    PostComponent,
    Post001Component,
    Post002Component,
    Post003Component,
    Post004Component,
    Post005Component,
    Post006Component,
    Post007Component,
    Post008Component,
    Post009Component,
    Post010Component,
    Post011Component,
    Post012Component,
    Post013Component,
    Post014Component,
    Post015Component,
    Post016Component,
    Post017Component,
    Post018Component,
    Post019Component,
    Post020Component,
} from './news-feed';
import { NavComponent } from './nav/nav.component';
import { AboutComponent } from './about/about.component';
import { FlipStickComponent } from './games/flip-stick/flip-stick.component';
import { ContactComponent } from './contact/contact.component';
import { FallBricksComponent } from './games/fall-bricks/fall-bricks.component';
import { Game3Component } from './games/game-3/game-3.component';
import { DropDownDirective } from './drop-down/drop-down.directive';
import { FooterComponent } from './footer/footer.component';
import { Error404Component } from './error-404/error-404.component';
import { PrivacyPolicyComponent } from './about/privacy-policy/privacy-policy.component';
import { LinkService } from './services/link.service';
import {
    VoDisclaimerComponent,
    VoHeaderComponent,
    VoStarsComponent,
    VoCardComponent,
    VoFooterComponent,
    VoComponent
} from './vo';
import { HideService } from './services/hide.service';
import { KoFiButtonComponent } from './ko-fi-button/ko-fi-button.component';
import { WishComponent } from './wish/wish.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        FlipStickPanelComponent,
        FallBricksPanelComponent,
        Game3PanelComponent,
        NavComponent,
        AboutComponent,
        ContactComponent,
        FlipStickComponent,
        FallBricksComponent,
        Game3Component,
        FooterComponent,
        Error404Component,
        DropDownDirective,
        PrivacyPolicyComponent,
        KoFiButtonComponent,
        PostComponent,
        Post001Component,
        Post002Component,
        Post003Component,
        Post004Component,
        Post005Component,
        Post006Component,
        Post007Component,
        Post008Component,
        Post009Component,
        Post010Component,
        Post011Component,
        Post012Component,
        Post013Component,
        Post014Component,
        Post015Component,
        Post016Component,
        Post017Component,
        Post018Component,
        Post019Component,
        Post020Component,

        VoComponent,
        VoDisclaimerComponent,
        VoFooterComponent,
        VoHeaderComponent,
        VoStarsComponent,
        VoCardComponent,

        WishComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
    ],
    providers: [
        LinkService,
        HideService,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }

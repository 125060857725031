import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-post-020',
    templateUrl: 'post-020.component.html',
    standalone: false
})
export class Post020Component {
    heading = 'Game 3 Shaders';
    postDate = new Date('2022-02-10');

    constructor(public linkService: LinkService) {

    }
}

<div class="card">
    <div class="card-header h5">
        Overview
    </div>
    <div class="card-body">
        <p class="card-text">
            We are happy to lend our voices to your animation, game, or other project.
        </p>
        <p class="card-text">
            We cannot accept any payment for lines of characters you do not own. Existing character impersonations are
            done for free. Original work will probably be free too.
        </p>
        <p class="card-text">
            Each character has a star rating intended to indicate the quality of the VO performance.
        </p>
        <p class="card-text">
            <app-vo-stars [count]="1"></app-vo-stars> One star: not a great performance, but you can tell who the
            character
            is.
        </p>
        <p class="card-text">
            <app-vo-stars [count]="2"></app-vo-stars> Two stars: a better performance, parody-level character voice.
        </p>
        <p class="card-text">
            <app-vo-stars [count]="3"></app-vo-stars> Three stars: a good performance. Some range may be missing.
        </p>
        <p class="card-text">
            <app-vo-stars [count]="4"></app-vo-stars> Four stars: a very good performance. Some range may be missing.
        </p>
        <p class="card-text">
            <app-vo-stars [count]="5"></app-vo-stars> Five stars: very close to the full range of the original
            character.
        </p>
        <p>
            This should give you an idea of the voice range available.
        </p>
        <p>Send your lines to <a href="mailto:pat@goatleap.com?Subject=VO%20Work%20Inquiry" target="_top">
                pat(at)goatleap.com</a> with the details of your project and I'll get back to you.
        </p>

    </div>
</div>
<app-post [heading]="heading" [postDate]="postDate">
    <p>
        I received a question about the shaders that change colours for character sprites, so here's an example of how
        it works.
    </p>

    <p>
        In the level editor tool, when you place an item onto the map, you can edit some of the settings. These settings
        are different depending on the item. For an NPC, you can set what shaders are used for various features. The
        same settings can be set for the player character in-game.
    </p>

    <div class="text-center mb-2">
        <img src="/assets/news-feed/post_020_editorExample.png" class="img-fluid" alt="Game 3 editor example" />
    </div>

    <p>
        And when the level is loaded into the game, the shaders are applied to the NPC.
    </p>
    <p>
        NPCs and the player character still have a pallette above them for debugging.
    </p>

    <div class="text-center mb-2">
        <img src="/assets/news-feed/post_020_shaderExample.png" class="img-fluid" alt="Game 3 shaders example" />
    </div>

</app-post>
<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/news-feed/post_007_cookies.png" class="img-fluid" alt="Cookies with 500 written on them." />
    </div>

    <p>
        Flip Stick has just reached 500 downloads! Thanks to everyone who downloaded! A new update is in the
        pipeline...
    </p>

    <p>
        The image above is of home-made cookies baked by a friend in celebration of 500 Flip Stick downloads! Thank you
        (it says 500).
    </p>

</app-post>
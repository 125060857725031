import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-flip-stick-panel',
    templateUrl: 'flip-stick-panel.component.html',
    standalone: false
})
export class FlipStickPanelComponent {

    constructor(public linkService: LinkService) { }

}

<h3 class="mt-2">Wish Lists</h3>

<div class="card mb-2">
    <h5 class="card-header">About this page</h5>
    <div class="card-body">
        This is a personal page to link wish lists to family and friends.
    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">Books</h5>
    <div class="card-body">
        <a href="https://www.thenile.com.au/wishlist/shared/lwBEd1UzFr-o4yDb" target="_blank">The Nile Bookstore wishlist</a>
    </div>
</div>

<div class="card mb-2">
    <h5 class="card-header">Games</h5>
    <div class="card-body">
        <a href="https://store.steampowered.com/wishlist/id/goatleap/" target="_blank">Steam wishlist</a>
    </div>
</div>
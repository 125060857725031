import { VoCardData } from '../models/vo-card-data';

export class ModelData {
    data: VoCardData[];

    constructor() {
        this.data = [
            {
                name: 'Gollum/Sméagol',
                origin: 'The Lord of the Rings movie trilogy',
                description: 'A very acuate impression with a full range of expressions shown in the movie trilogy.',
                stars: 5,
                graphicPath: 'assets/vo/gollum.png',
                showDisclaimer: true
            },
            {
                name: 'Mr Krabs',
                origin: 'Spongebob Squarepants',
                description: 'Are you feeling now, Mr Krabs?',
                stars: 3,
                graphicPath: 'assets/vo/mr-krabs.png',
                showDisclaimer: true
            },
            {
                name: 'Squidward',
                origin: 'Spongebob Squarepants',
                description: 'Plays the clarinet all day and doesn\'t get any better.',
                stars: 3,
                graphicPath: 'assets/vo/squidward.png',
                showDisclaimer: true
            },
            {
                name: 'Time Card Voice',
                origin: 'Spongebob Squarepants',
                description: 'Any time card gag you can think of.',
                stars: 3,
                graphicPath: 'assets/vo/time-card.png',
                showDisclaimer: true
            },
            {
                name: 'Painty the Pirate',
                origin: 'Spongebob Squarepants',
                description: 'A decent generic pirate voice. Similar to Mr Krabs.',
                stars: 3,
                graphicPath: 'assets/vo/painty-the-pirate.png',
                showDisclaimer: true
            },
            {
                name: 'Nappa',
                origin: 'Dragon Ball Z',
                description: 'Mostly questions about power levels, and variations on lines from the original character.',
                stars: 2,
                graphicPath: 'assets/vo/nappa.png',
                showDisclaimer: true
            },
            {
                name: 'Vegeta',
                origin: 'Dragon Ball Z',
                description: 'Not a good impression, but you get the point.',
                stars: 1,
                graphicPath: 'assets/vo/vegeta.png',
                showDisclaimer: true
            },
            {
                name: 'Shenron, The Eternal Dragon',
                origin: 'Dragon Ball Z',
                description: 'A very deep and croaky voice.',
                stars: 1,
                graphicPath: 'assets/vo/shenron.png',
                showDisclaimer: true
            },
            {
                name: 'Jeice',
                origin: 'Dragon Ball Z',
                description: 'Australians are my specialty.',
                stars: 1,
                graphicPath: 'assets/vo/jeice.png',
                showDisclaimer: true
            },
            {
                name: 'Snake Jailbird',
                origin: 'The Simpsons',
                description: 'Everyone seems to be able to do a version of this voice.',
                stars: 1,
                graphicPath: 'assets/vo/snake-jailbird.png',
                showDisclaimer: true
            },
            {
                name: 'Zoidburg',
                origin: 'Futarama',
                description: 'Not a good impression, but you get the point.',
                stars: 1,
                graphicPath: 'assets/vo/zoidburg.png',
                showDisclaimer: true
            },
            {
                name: 'Toad',
                origin: 'Super Mario',
                description: 'The high-pitched version of Toad that started around the release of Super Mario Sunshine.',
                stars: 2,
                graphicPath: 'assets/vo/toad.png',
                showDisclaimer: true
            },
            {
                name: 'Wario',
                origin: 'Super Mario',
                description: 'Waaaa.',
                stars: 1,
                graphicPath: 'assets/vo/wario.png',
                showDisclaimer: true
            },
            {
                name: 'Waluigi',
                origin: 'Super Mario',
                description: 'Take that, Mr. eyeballs!',
                stars: 2,
                graphicPath: 'assets/vo/waluigi.png',
                showDisclaimer: true
            },
            {
                name: 'Weapons Merchant',
                origin: 'Resident Evil 4',
                description: 'What are ya buyin\'',
                stars: 2,
                graphicPath: 'assets/vo/merchant.png',
                showDisclaimer: true
            },
        ];
    }
}

import { Component } from '@angular/core';

@Component({
    selector: 'app-post-015',
    templateUrl: 'post-015.component.html',
    standalone: false
})
export class Post015Component {
    heading = 'Game 3 March Development Update';
    postDate = new Date('2015-03-31');
}

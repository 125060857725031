import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FlipStickComponent } from './games/flip-stick/flip-stick.component';
import { FallBricksComponent } from './games/fall-bricks/fall-bricks.component';
import { Game3Component } from './games/game-3/game-3.component';
import { Error404Component } from './error-404/error-404.component';
import { PrivacyPolicyComponent } from './about/privacy-policy/privacy-policy.component';
import { VoComponent } from './vo';
import { WishComponent } from './wish/wish.component';

const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'about', component: AboutComponent },
    { path: 'about/privacy-policy', component: PrivacyPolicyComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'games/flip-stick', component: FlipStickComponent },
    { path: 'games/fall-bricks', component: FallBricksComponent },
    { path: 'games/game-3', component: Game3Component },
    { path: 'vo', component: VoComponent },
    { path: 'wish', component: WishComponent },
    { path: '**', component: Error404Component },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

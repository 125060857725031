import { Component } from '@angular/core';

@Component({
    selector: 'app-vo-disclaimer',
    templateUrl: 'vo-disclaimer.component.html',
    standalone: false
})
export class VoDisclaimerComponent {

}

import { Component } from '@angular/core';

@Component({
    selector: 'app-post-013',
    templateUrl: 'post-013.component.html',
    standalone: false
})
export class Post013Component {
    heading = 'Planning updates for Flip Stick and Fall Bricks';
    postDate = new Date('2015-02-06');
}

import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-post-019',
    templateUrl: 'post-019.component.html',
    standalone: false
})
export class Post019Component {
    heading = 'Game 3 Update 2021';
    postDate = new Date('2021-12-23');

    constructor(public linkService: LinkService) {

    }
}

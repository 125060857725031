import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-post-018',
    templateUrl: 'post-018.component.html',
    standalone: false
})
export class Post018Component {
    heading = 'New Fall Bricks Build';
    postDate = new Date('2020-09-26');

    constructor(public linkService: LinkService) {

    }
}

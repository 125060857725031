import { Component } from '@angular/core';

@Component({
    selector: 'app-post-009',
    templateUrl: 'post-009.component.html',
    standalone: false
})
export class Post009Component {
    heading = 'Next Project... Game 3!';
    postDate = new Date('2013-09-10');
}

<div class="card mb-2">
    <h5 class="card-header">Fall Bricks</h5>
    <div class="card-body">
        <div class="text-center mb-2">
            <img src="/assets/fall-bricks/fallbricks_icon_rounded.png" alt="Fall Bricks Logo" />
        </div>
        <p class="mb-2">
            A free puzzle game for iOS. Stack bricks to make groups of 10 to score points.
        </p>
        <div class="mb-2">
            First release:
            <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%">
                    100%
                </div>
            </div>
        </div>
        <div class="mb-2">
            Current version: 1.4.1
            <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%">
                    100%
                </div>
            </div>
        </div>
        <div class="mb-2 text-center">
            <button class="btn btn-primary me-2" routerLink="games/fall-bricks" role="button">More</button>
            <a class="btn btn-primary" href="{{linkService.fallBricksDownloadLink()}}" role="button">Download</a>
        </div>
    </div>
</div>
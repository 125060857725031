import { Component } from '@angular/core';

@Component({
    selector: 'app-post-007',
    templateUrl: 'post-007.component.html',
    standalone: false
})
export class Post007Component {
    heading = '500 Flip Stick Downloads!';
    postDate = new Date('2013-05-07');
}

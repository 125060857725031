import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-post-008',
    templateUrl: 'post-008.component.html',
    standalone: false
})
export class Post008Component {
    heading = 'Fall Bricks Released!';
    postDate = new Date('2013-09-08');

    constructor(public linkService: LinkService) { }
}

<app-post [heading]="heading" [postDate]="postDate">

    <div class="mb-2 text-center">
        <img src="/assets/news-feed/post_006_logoChange.png" class="img-fluid"
            alt="Iron Summers icon is now Goat Leap icon" />
    </div>

    <p>
        The website transfer from IronSummers to Goat Leap is finished... I think. The old website now redirects to this
        one, and the old content has been moved. I'm using a blog to manage the development updates and all of the old
        posts from IronSummers have been moved over to the new system. I've also renamed the Twitter and Facebook
        accounts, which was surprisingly easy. Please Follow/Like us.
    </p>

    <p>
        There are lots of reasons that made me change <i>IronSummers</i> to <i>Goat Leap</i>, and I thought that it
        would be best to do it sooner rather than later, and before the second game was done.
    </p>

    <p>
        Let me know what you think of the new site, or if you find something that looks unfinished, or a broken link. I
        think I like the new site better than the old one.
    </p>

    <p>
        Now I can get back to work on the second game:
    </p>

    <ul>
        <li>The gameplay and menus are done, and most of the graphics too.</li>
        <li>There are some visual effects and animation that need to be put in place.</li>
        <li>All of the music and sound effects need to be done, which will take some time.</li>
        <li>Then there should only be final QA and bug hunts.</li>
    </ul>

    <p>
        I'll post here when I have an update.
    </p>

</app-post>
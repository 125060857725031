import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'error-404.component.html',
    standalone: false
})
export class Error404Component implements OnInit {

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        this.router.navigate(['/']);
    }
}

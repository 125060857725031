import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-post-005',
    templateUrl: 'post-005.component.html',
    standalone: false
})
export class Post005Component {
    heading = 'Flip Stick Update 1.0.2';
    postDate = new Date('2012-11-20');

    constructor(public linkService: LinkService) { }
}
